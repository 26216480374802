import { gql } from './__generated__';

gql(`fragment DayTime on CalendarDayTime {
	day {
		year
		month
		day
	}
	time {
		hour
		minute
	}
}
fragment Day on CalendarDay {
	year
	month
	day
}
fragment Time on CalendarTime {
	hour
	minute
}
fragment Currency on Currency {
	value
	code
	displayValue
}
fragment CurrencyWithCents on Currency {
	...Currency
	cents
}
fragment Address on Address {
	street
	street2
	city
	state
	country
	postalCode
}`);
