import { withScope } from '@sentry/solidstart';
import { GraphQLError, Kind } from 'graphql';
import { mapExchange } from '@urql/core';
import type { Exchange } from '@urql/core';

export const sentryExchange: Exchange = mapExchange({
	onError(error, operation) {
		// Ignore loggedInUser queries, as they're expected to fail when not logged in
		if (
			error?.graphQLErrors.some((err) => err.extensions.unauthorized) ||
			operation.query.definitions.some(
				(def) => def.kind === Kind.OPERATION_DEFINITION && def.name?.value === 'loggedInUser',
			)
		) {
			return;
		}

		withScope((scope) => {
			scope.setTag('kind', operation.kind);
			scope.setExtra('query', operation.query.loc?.source.body);
			scope.setExtra('queryName', operation.query.loc?.source.name);
			scope.setExtra('variables', operation.variables);

			for (const err of error.graphQLErrors) {
				const { message, ...rest } = err;
				if (!ignored.find((i) => i.test(message))) {
					scope.setExtra('path', rest.path);
					scope.captureException(new GraphQLError(message, rest));
				}
			}
		});
	},
});

const ignored: Array<RegExp> = [/failed to login/i, /forgot password request/i, /invalid confirmation code/i];
